import StorageService from "service/storage/StorageService";
import SessionManager from "./SessionManager";

import Gender from "constants/type/user/Gender";

import fem1 from "assets/icons/fem_1.png";
import fem2 from "assets/icons/fem_2.png";
import fem3 from "assets/icons/fem_3.png";
import fem4 from "assets/icons/fem_4.png";
import fem5 from "assets/icons/fem_5.png";
import fem6 from "assets/icons/fem_6.png";
import fem7 from "assets/icons/fem_7.png";
import fem8 from "assets/icons/fem_8.png";
import fem9 from "assets/icons/fem_9.png";
import fem10 from "assets/icons/fem_10.png";
import fem11 from "assets/icons/fem_11.png";
import fem12 from "assets/icons/fem_12.png";
import male1 from "assets/icons/male_1.png";
import male2 from "assets/icons/male_2.png";
import genderIcon from "assets/icons/gender.png";
class AuthenticationService {
  constructor() {
    this.storage = new StorageService();
    this.session = new SessionManager();

    this.KEY_USER_PROFILE = "USER_PROFILE";
    this.KEY_CLIENT = "client";
    this.KEY_PHONE_NO = "phone_no";
    this.KEY_USER_IDENTITY = "USER_IDENTITY";
    this.KEY_TENANT = "TENANT_ID";

    this.CLIENT = "bridge_web";

    this.setDefaultCookies();
  }

  activateSession(token) {
    this.session.setSession(token, 1051200); //2 years
  }

  getSessionToken() {
    return this.session.getSession();
  }

  logout() {
    this.session.deleteSession();
    this.storage.clearAll();
  }

  isLoggedIn() {
    let user = this.getUserProfile();
    let sessionToken = this.session.getSession();

    if (sessionToken) {
      return true;
    }

    return false;
  }

  setUserProfile(user) {
    this.storage.set(this.KEY_USER_PROFILE, user);
  }

  getUserProfile() {
    return this.storage.get(this.KEY_USER_PROFILE);
  }

  setTenantId(tenantId) {
    this.storage.set(this.KEY_TENANT, tenantId);
  }

  getTenantId() {
    return this.storage.get(this.KEY_TENANT);
  }

  isTenantDefined() {
    let tenant = this.getTenantId();
    if (tenant) {
      return true;
    }
    return false;
  }

  setLoggedInCookies() {


  
      this.session.setCookie(
        this.KEY_PHONE_NO,
        this.getUserProfile().phoneno,
        1051200
      ); // 2 years

  
    
  }

  setDefaultCookies() {
    this.session.setCookie(this.KEY_CLIENT, this.CLIENT, 1051200); // 2 years

    if (this.isLoggedIn()) {
      this.session.setCookie(
        this.KEY_PHONE_NO,
        this.getUserProfile().phoneno,
        1051200
      ); // 2 years

      // one time
      this.activateSession(this.getSessionToken());
    }
  }

  getUserName() {
    let name = "User";
    let user = this.getUserProfile();

    if (user && user.employeeProfile) {
      if (user.employeeProfile.firstName) {
        name =
          user.employeeProfile.firstName +
          (user.employeeProfile.lastName
            ? " " + user.employeeProfile.lastName
            : "");
      }
    } else if (user && user.doctorProfile) {
      if (user.doctorProfile.name) {
        name = user.doctorProfile.name;
      }
    }

    return name;
  }

  getUserProfileImage() {
    let image;
    let user = this.getUserProfile();

    if (user && user.employeeProfile) {
      image = user.employeeProfile.profileImage
        ? user.employeeProfile.profileImage
        : undefined;
    } else if (user && user.doctorProfile) {
      image = user.doctorProfile.profile_image
        ? user.doctorProfile.profile_image
        : undefined;
    }

    let gender = 1;
    if (user && user.doctorProfile) {
      gender = user.doctorProfile.gender;
    }
    if (user && user.employeeProfile) {
      gender = user.employeeProfile.gender;
    }

    let females = [
      fem1,
      fem2,
      fem3,
      fem4,
      fem5,
      fem6,
      fem7,
      fem8,
      fem9,
      fem10,
      fem11,
      fem12,
    ];
    let males = [male1, male2];

    let rand;

    if (!image) {
      let storedIdentity = this.storage.get(this.KEY_USER_IDENTITY);

      switch (gender) {
        case Gender.FEMALE:
          rand = storedIdentity
            ? storedIdentity
            : Math.floor(Math.random() * (+12 - +1)) + +1;
          image = females[rand - 1];
          break;
        case Gender.MALE:
          rand = storedIdentity
            ? storedIdentity
            : Math.floor(Math.random() * (+2 - +1)) + +1;
          image = males[rand - 1];

          break;
        default:
          image = genderIcon;
          break;
      }
      if (image === undefined) {
        image = genderIcon;
      }
      this.storage.set(this.KEY_USER_IDENTITY, rand);
    }

    return image;
  }
}

export default AuthenticationService;
