import EnvironmentService from "service/environment/EnvironmentService";

let environmentService = new EnvironmentService();
let baseAPI = environmentService.getBaseAPI();
let serviceAPI = environmentService.getServiceAPI();
let serviceSocket = environmentService.getServiceSocket();
let serviceAPINew = environmentService.getServiceAPINew();
let localService = environmentService.getLocalService(); //8080
const APIConfig = {
  base: baseAPI,
  microService: serviceAPI,

  tenant: {
    getTenant: serviceAPI + "/v2/tenant",
  },

  user: {
    getOTP: baseAPI + "/employees/otp/get",
    getOTPNew: baseAPI + "/employees/otp/v3/get",

    // verifyOTP: baseAPI + "/employees/otp/verify",
    getDoctorOTP: baseAPI + "/doclogin/otp/get",
    // verifyDoctorOTP: baseAPI + "/doclogin/otp/verify",
    new: baseAPI + "/employees/new",
    getAll: baseAPI + "/employees/all",
    get: baseAPI + "/employees/{0}",
    verifyDocCode: baseAPI + "/docprofile/doctor?code={0}",
    verifyOTP: baseAPI + "/employees/otp/v2/verify", //new added
    verifyOTPNew: baseAPI + "/employees/otp/v3/verify", //new added

    verifyDoctorOTP: baseAPI + "/doclogin/otp/v2/verify", //new added
    token: baseAPI + "/employees/token", //newadded
    memberMapping: baseAPI + "/client/member/{0}/getbyphone",
    latestUserProfile: serviceAPI + "/v1/patients/{0}",
    deliveryDate: serviceAPI + "/v2/pa/assessments/deliverydate/{0}",
  },

  coupons: {
    get: serviceAPINew + "/api/v1/coupon/verify?phoneno={0}&code={1}",
    generate: serviceAPINew + "/api/v1/coupon/create",
  },

  doctor: {
    getAll: baseAPI + "/doctor/all",
    addNew: baseAPI + "/doctor/add",
    update: baseAPI + "/doctor/",
    activate: baseAPI + "/doctor/update/status",
    deactivate: baseAPI + "/doctor/update/status",
    invite: baseAPI + "/invite",
    getInvite: baseAPI + "/doctor/patients/invites",
  },
  broadcaster: {
    fetchAllJobs: serviceAPI + "/api/v1/broadcaster/getByAll",
    newJob: serviceAPI + "/api/v1/broadcaster/create",
    fetchJobById: serviceAPI + "/api/v1/broadcaster/getById/{0}",
    updateJob: serviceAPI + "/api/v1/broadcaster/update/{0}",
  },
  patient: {
    new: baseAPI + "/patients/new",
    newProfile: baseAPI + "/patients/profile/new",
    getAll: baseAPI + "/patients/all",
    get: baseAPI + "/patients/{0}",
    getProfileIds:
      baseAPI + "/patients/profile?phoneno={0}&countrycode={1}&tenantid={2}",
    updateParams: baseAPI + "/patients/",
    updateParamGroups: baseAPI + "/patients/{0}/groups",
    getTrackingInfo: baseAPI + "/patients/{0}/track?doctorId={1}",
    updateStatus: baseAPI + "/patients/{0}/update/status",
    invite: baseAPI + "/patients/{0}/invite",
    updateEmail: baseAPI + "/patients/{0}/email",
    search: baseAPI + "/patients/search?q={0}&type={1}&paid={2}&tenantid={3}",
    searchV2: baseAPI + "/patients/search?q={0}&type={1}",
    searchFromDoctor:
      baseAPI + "/doctor/patients/search?q={0}&type={1}&from={2}&size={3}",
    verifyStatus: baseAPI + "/patients/{0}/verify",
    prescription: baseAPI + "/patients/{0}/verify",
    searchPhoneno:
      baseAPI + "/patients/search?q={0}&type={1}&paid={2}&cc={3}&tenantid={4}",
    searchPhoneno2: baseAPI + "/patients/search?q={0}&type=3&cc={1}",
    searchPhoneno3: baseAPI + "/patients/{0}/gpbphn?countryCode={1}",
    searchProfileExist:
      baseAPI +
      "/patients/{0}/gpbphn?countryCode={1}&firstName={2}&lastName={3}",
    referrer: baseAPI + "/patients/{0}/referrer",
    primaryTherapy: serviceAPI + "/v2/pa/assessments/{0}/primary",
    therapyLabTest: serviceAPI + "/api/v2/ltbypt/{0}",
    therapyQuestions: serviceAPI + "/v2/pa/assessments/questions/1",
    changeTherapy: serviceAPI + "/v2/pa/assessments/{0}",
    address: baseAPI + "/address/{0}",
    pinCode: serviceAPI + "/api/v1/address/pincode/{0}",
    getSettings: serviceAPI + "/v1/patients/{0}/settings",
    patchSettings: serviceAPI + "/v1/patients/{0}/settings",
    athenaInsight: {
      getInsight:
        serviceAPI +
        "/v2/athena/dependent/causal/{0}?size={1}&maxId={2}&minId={3}",
      updateInsight: serviceAPI + "/v2/athena/dependent/causal/{0}/{1}",
      shouldBlink: serviceAPI + "/v2/athena/dependent/causal/{0}/chkp",
    },
    walletInfo: serviceAPI + "/v1/wallet/admin/{0}",
    deliveryDueDate: serviceAPI + "/v2/pa/assessments/deliverydate",
  },
  tracking: {
    chiefComplaint: {
      add: serviceAPI + "/api/v1/tracking/cc/{0}",
      get: serviceAPI + "/api/v1/tracking/cc/{0}/count",
      getNext: serviceAPI + "/api/v1/tracking/cc/{0}?pageSize={1}&pageNo={2}",
      zohoAdd: serviceAPI + "/v1/tm/project/task",
      zohoUpdate: serviceAPI + "/v1/tm/project/task",
      userByProject: serviceAPI + "/v1/tm/users/{0}",
    },
    surgery: {
      add: serviceAPI + "/api/v1/tracking/surgery/{0}",
      get: serviceAPI + "/api/v1/tracking/surgery/{0}/count",
      getNext:
        serviceAPI + "/api/v1/tracking/surgery/{0}?pageSize={1}&pageNo={2}",
    },
    medicine: {
      add: serviceAPI + "/api/v1/tracking/md/{0}",
      get: serviceAPI + "/api/v1/tracking/md/{0}/count",
      getNext: serviceAPI + "/api/v1/tracking/md/{0}?pageSize={1}&pageNo={2}",
    },
    labtest: {
      add: serviceAPI + "/api/v1/tracking/li/{0}",
      // update: serviceAPI + "/api/v1/tracking/li/{0}/{1}",
      update: serviceAPI + "/api/v1/tracking/li/{0}",
      // add: serviceAPI + "/api/v1/tracking/li/{0}/latest/tracking",
      // update: serviceAPI + "/api/v1/tracking/li/{0}/latest/tracking",
      get: serviceAPI + "/api/v1/tracking/li/{0}/count",
      getNext: serviceAPI + "/api/v1/tracking/li/{0}?pageSize={1}&pageNo={2}",
      getLatest: serviceAPI + "/api/v1/tracking/li/{0}/latest/tracking",
      getDocumentId: serviceAPI + "/v1/documents/digitisation/{0}",
      searchByDocId: serviceAPI + "/api/v1/tracking/li/{0}/conditions",
    },
    diagnosis: {
      add: serviceAPI + "/api/v1/tracking/dh/{0}",
      get: serviceAPI + "/api/v1/tracking/dh/{0}/count",
      getNext: serviceAPI + "/api/v1/tracking/dh/{0}?pageSize={1}&pageNo={2}",
    },
    addictions: {
      add: serviceAPI + "/api/v1/tracking/addiction/{0}",
      get: serviceAPI + "/api/v1/tracking/addiction/{0}/count",
      getNext:
        serviceAPI + "/api/v1/tracking/addiction/{0}?pageSize={1}&pageNo={2}",
    },
    stepAndSleep: {
      get:
        serviceAPI +
        "/api/v1/sleep-step-tracker/bridge-data/{0}?from={1}&to={2}",
    },
  },

  feedback: {
    getAll: serviceAPI + "/v1/feedback",
  },

  unit: {
    new: baseAPI + "/units/new",
    getAll: baseAPI + "/units/all",
    get: baseAPI + "/units/",
  },

  param: {
    new: baseAPI + "/params/new",
    getAll: baseAPI + "/params/all",
    get: baseAPI + "/params/",
    update: baseAPI + "/params/{0}/update",
    group: {
      new: baseAPI + "/params/groups/new",
      getAll: baseAPI + "/params/groups/all",
      get: baseAPI + "/params/groups/{0}",
      update: baseAPI + "/params/groups/{0}",
    },
  },

  goal: {
    master: {
      new: baseAPI + "/goals/master/new",
      getAll: baseAPI + "/goals/master/all",
      get: baseAPI + "/goals/master/",
      update: baseAPI + "/goals/master/",
    },
    new: baseAPI + "/goals/new",
    getAll: baseAPI + "/goals/all",
    get: baseAPI + "/goals/",
    update: baseAPI + "/goals/",
  },

  task: {
    master: {
      new: baseAPI + "/tasks/master/new",
      getAll: baseAPI + "/tasks/master/all",
      get: baseAPI + "/tasks/master/",
      update: baseAPI + "/tasks/master/",
    },
    recurring: {
      new: baseAPI + "/tasks/recurring/new",
      update: baseAPI + "/tasks/recurring/",
    },
    new: baseAPI + "/tasks/new",
    getAll: baseAPI + "/tasks/all",
    get: baseAPI + "/tasks/",
    update: baseAPI + "/tasks/",
  },

  communication: {
    send: baseAPI + "/communication/send",
  },

  paramValue: {
    new: baseAPI + "/params/value/new",
    getAllByPatient: baseAPI + "/params/value/patients/{0}?dueDate={1}",
    get: baseAPI + "/params/",
    update: baseAPI + "/params/value/",
  },

  role: {
    new: baseAPI + "/roles/new",
    getAll: baseAPI + "/roles/all",
    update: baseAPI + "/roles/{0}/update",
    roleById: baseAPI + "/roles/{0}",
  },

  permission: {
    getAll: baseAPI + "/permissions/all",
  },

  feed: {
    newInformationCard: baseAPI + "/feeds/information/new",
    getInformationCards: baseAPI + "/feeds/information/all",
    getInformationCard: baseAPI + "/feeds/information/{0}",
    // updateInformationCard: baseAPI + "/feeds/information/update/{0}",
    updateInformationCard: serviceAPI + "/api/v1/dyn/{0}",
    fetchAll: serviceAPI + "/api/v1/dyn?page={0}",
    therapy: serviceAPI + "/api/v1/dyn/pt",
    newDyn: serviceAPI + "/api/v1/dyn",
    image: serviceAPI + "/api/v1/dyn/file/upload",
  },

  checklistMaster: {
    new: baseAPI + "/checklists/master/new",
    get: baseAPI + "/checklists/master/{0}",
    getAll: baseAPI + "/checklists/master/all",
    update: baseAPI + "/checklists/master/{0}",
  },

  checklist: {
    new: baseAPI + "/checklists/new",
    get: baseAPI + "/checklists/{0}",
    getAll: baseAPI + "/checklists/all",
    update: baseAPI + "/checklists/{0}",
    getByPatientDoctor:
      baseAPI + "/checklists/patient-doctor?patientId={0}&doctorId={1}",
  },

  checklistValue: {
    new: baseAPI + "/checklists/values/new",
    get: baseAPI + "/checklists/values/{0}",
    getAll: baseAPI + "/checklists/values/all",
    update: baseAPI + "/checklists/values/{0}",
    getByPatientDoctor:
      baseAPI +
      "/checklists/values/patient-doctor?patientId={0}&doctorId={1}&dueDate={2}",
  },

  careTeam: {
    new: baseAPI + "/careteams/new",
    get: baseAPI + "/careteams/{0}",
    getAll: baseAPI + "/careteams/all",
    update: baseAPI + "/careteams/{0}",
    getByPatient: baseAPI + "/careteams/patients/{0}",
  },

  call: {
    new: baseAPI + "/calls/new",
    get: baseAPI + "/calls/{0}",
    getDoctorName: baseAPI + "/calls/doctor/{0}",
    getAll: baseAPI + "/calls/all",
    update: baseAPI + "/calls/{0}",
    getCallPlan: baseAPI + "/calls/callplan?caller={0}&callee={1}&dueDate={2}",
    getCallPlanByCallee:
      baseAPI + "/calls/callplan/callee?callee={0}&fromDate={1}&toDate={2}",
    getDoctorNamesAndDueDates:
      baseAPI +
      "/calls/all_callers_with_due_date?callee={0}&fromDate={1}&toDate={2}",
  },

  callNote: {
    new: baseAPI + "/calls/notes/new",
    get: baseAPI + "/calls/notes/{0}",
    getAll: baseAPI + "/calls/notes/all",
    update: baseAPI + "/calls/notes/{0}",
    disable: baseAPI + "/calls/notes/disable/{0}",
    getAllByCall: baseAPI + "/calls/notes/calls/{0}",
    detailedNote: baseAPI + "/calls/notes/details/{0}",
    directMessage: serviceAPI + "/v1/chat/users/patients/{0}/message",
    getUserId: serviceAPI + "/api/v1/user/patient/{0}",
  },

  callToDo: {
    new: baseAPI + "/calls/todos/new",
    get: baseAPI + "/calls/todos/{0}",
    getAll: baseAPI + "/calls/todos/all",
    update: baseAPI + "/calls/todos/{0}",
    getAllByCall: baseAPI + "/calls/todos/calls/{0}",
  },

  medication: {
    new: baseAPI + "/medications/new",
    get: baseAPI + "/medications/{0}",
    getAll: baseAPI + "/medications/all",
    update: baseAPI + "/medications/{0}",
  },

  chiefComplaint: {
    new: baseAPI + "/chief-complaints/new",
    get: baseAPI + "/chief-complaints/{0}",
    getAll: baseAPI + "/chief-complaints/all",
    update: baseAPI + "/chief-complaints/{0}",
  },

  reporting: {
    new: baseAPI + "/reportings/new",
    get: baseAPI + "/reportings/{0}",
    getAll: baseAPI + "/reportings/all",
    update: baseAPI + "/reportings/{0}",
    getByPatient: baseAPI + "/reportings/patients/{0}?dueDate={1}",
  },

  document: {
    new: baseAPI + "/documents/new",
    get: baseAPI + "/documents/{0}",
    getAll: baseAPI + "/documents/all",
    getAllFolders: baseAPI + "/documents/folders/all",
    update: baseAPI + "/documents/{0}",
    updateType: baseAPI + "/documents/{0}/update-type/{1}",
    updateNameAndDescription:
      baseAPI + "/documents/{0}/update-name-and-description",
    getByPatient: baseAPI + "/documents/patients/{0}?dueDate={1}",
    getDocumentByPatientId: baseAPI + "/documents/patients/{0}",
  },

  cuisine: {
    new: baseAPI + "/cuisines/new",
    get: baseAPI + "/cuisines",
    getAll: baseAPI + "/cuisines/all",
    update: baseAPI + "/cuisines/",
  },

  medicalCondition: {
    new: baseAPI + "/medical_conditions/new",
    get: baseAPI + "/medical_conditions",
    getAll: baseAPI + "/medical_conditions/all",
    update: baseAPI + "/medical_conditions/",
  },

  constituent: {
    new: baseAPI + "/constituents/new",
    get: baseAPI + "/constituents",
    getAll: baseAPI + "/constituents/all",
    update: baseAPI + "/constituents/",
  },

  recipe: {
    new: baseAPI + "/recipes/new",
    get: baseAPI + "/recipes",
    getAll: baseAPI + "/recipes/all",
    update: baseAPI + "/recipes/",
  },

  dish: {
    master: {
      new: baseAPI + "/dish_master/new",
      getAll: baseAPI + "/dish_master/all",
      get: baseAPI + "/dish_master",
      update: baseAPI + "/dish_master/",
    },
    new: baseAPI + "/dishes/new",
    getAll: baseAPI + "/dishes/all",
    get: baseAPI + "/dishes",
    update: baseAPI + "/dishes/",
  },

  dishConstituent: {
    new: baseAPI + "/dish_constituents/new",
    get: baseAPI + "/dish_constituents",
    getAll: baseAPI + "/dish_constituents/all",
    update: baseAPI + "/dish_constituents/",
  },

  dishMedicalCondition: {
    new: baseAPI + "/dish_medical_conditions/new",
    get: baseAPI + "/dish_medical_conditions",
    getAll: baseAPI + "/dish_medical_conditions/all",
    update: baseAPI + "/dish_medical_conditions/",
  },

  salesPatient: {
    getAll: baseAPI + "/sales/patients/all?pageNumber={0}&pageSize={1}",
    updateProfile: baseAPI + "/sales/patients/{0}/profile",
  },

  onboardingDetail: {
    new: baseAPI + "/onboarding-details/new",
    get: baseAPI + "/onboarding-details/{0}",
    getAll: baseAPI + "/onboarding-details/all",
    update: baseAPI + "/onboarding-details/{0}",
  },

  testReading: {
    new: baseAPI + "/test-readings/new",
    update: baseAPI + "/test-readings/{0}",
    // updateRecommended: baseAPI + "/test-readings/update-recommended/{0}",
    updateRecommended: serviceAPI + "/api/v2/vitals/{0}/recommended",
    get: serviceAPI + "/api/v2/vitals/{0}?forDate={1}",
    getPatientsTestReadings: serviceAPI + "/api/v2/vitals/{0}/all?forDate={1}",
    // updateVitals: serviceAPI + "/api/v2/vitals/{0}?forDate={1} ",
    //new
    updateVitals: serviceAPI + "/api/v2/vitals/{0}/onbridge?forDate={1}",
    auditTrails: serviceAPI + "/api/v2/vitals/{0}/audit/{1}?forDate={2}",
  },

  careManagerService: {
    get: serviceAPI + "/api/v1/chat/user/{0}/token",
    getAll: serviceAPI + "/api/v1/chat/users/all",
    getAllActivity: serviceAPI + "/api/v1/chat/user/activity",
    getAllForAsync: serviceAPI + "/api/v1/chat/ws/users",
    update: serviceAPI + "/api/v1/chat/cm/{0}/token/generate",
    patchupdate: serviceAPI + "/api/v1/user/{0}",
    deleteUser: serviceAPI + "/api/v1/chat/user/{0}/sm/continue",
  },
  careManagerSocket: {
    getAll: serviceSocket + "/api/v1/chat/users",
  },

  routineReporting: {
    getAll: serviceAPI + "/api/v1/report/{0}",
    getWithDateSpecified: serviceAPI + "/api/v2/report/{0}?date={1}",
  },

  meditation: {
    getAll: baseAPI + "/meditations/all",
    get: baseAPI + "/meditations/{0}",
  },

  progressNote: {
    new: serviceAPI + "/api/v1/progress_notes",
    getAll: serviceAPI + "/api/v1/progress_notes/{0}?interval={1}&number={2}",
    update: serviceAPI + "/api/v1/progress_notes/{0}",
  },

  nutritionNote: {
    new: serviceAPI + "/api/v1/nutrition_notes",
    getAll: serviceAPI + "/api/v1/nutrition_notes/{0}?interval={1}&number={2}",
    update: serviceAPI + "/api/v1/nutrition_notes/{0}",
  },

  detailedAssessment: {
    getDataTables: serviceAPI + "/api/v1/dt/referenceList/getAllDetails",
    getDataAssissment: serviceAPI + "/api/v1/da/{0}?userId={1}&userName={2}",
    update:
      serviceAPI + "/api/v1/da/{0}/update/{1}?isPDUpdated={2}&userName={3}",
    getIcd: serviceAPI + "/api/v1/dt/icd/search?queryString={0}",
    getSurgeries: serviceAPI + "/api/v1/dt/surgery/search?queryString={0}",
    getMedicines: serviceAPI + "/api/v1/dt/medicines/searchV1?queryString={0}",
    getLabTest: serviceAPI + "/api/v1/dt/labtest/search?queryString={0}",
    getSubTest:
      serviceAPI + "/api/v1/dt/labtest/{0}/sublabtests/search?queryString={1}",
    getSubTestData:
      serviceAPI + "/api/v1/dt/labtest/{0}/sublabtests/onselect/ {1}",
    getSubTestData:
      serviceAPI + "/api/v1/dt/labtest/ {0}/sublabtests/onselect/{1}",
    fetchDAbyID: serviceAPI + "/api/v1/da/{0}/getById/{1}",
    fetchMedicineByCode: serviceAPI + "/api/v1/dt/medicines/getByCodes",
    hraScore: serviceAPI + "/v1/hra/da/{0}?userId={1}&userName={2}",
  },

  newProgressNote: {
    new: serviceAPI + "/api/v1/progress_notes",
    getAll:
      serviceAPI +
      '/api/v1/progress_notes?where={"patientId":{0}}&embedded={"comments":1}&max_results={1}&page={2}&sort=[("_id",-1)]&time={3}',
    getAllWithType:
      serviceAPI +
      '/api/v1/progress_notes?where={"patientId":{0},"tag":{3}}&embedded={"comments":1}&max_results={1}&page={2}&sort=[("_id",-1)]&time={4}',
    update: serviceAPI + "/api/v1/progress_notes/{0}",
    search:
      serviceAPI +
      "/api/v1/progress_notes?where={%22patientId%22:{0},%20%22body%22:%20{%22%24regex%22:%20%20%22{4}%22,%20%20%22%24options%22:%20%22i%22}}&embedded={%22comments%22:1}&sort=[(%22_id%22,-1)]",
  },
  //
  // "/api/v1/progress_notes?where={%22patientId%22:{0},%20%22body%22:%20{%22%24regex%22:%20%20%22{4}%22,%20%20%22%24options%22:%20%22i%22}}&embedded={%22comments%22:1}&max_results={1}&page={2}&sort=[(%22_id%22,-1)]",
  // %22tag%22:{3},
  progressNoteComment: {
    new: serviceAPI + "/api/v1/comments",
    update: serviceAPI + "/api/v1/comments/{0}",
  },
  progressNoteTaskZoho: {
    newTask: serviceAPI + "/v1/tm/project/task",
    // users: serviceAPI + "/v1/tm/users",
    projects: serviceAPI + "/v1/tm/projects",
    userByProject: serviceAPI + "/v1/tm/users/{0}",
  },

  progressNoteTask: {
    newTask: serviceAPI + "/api/v1/asana/task",
    users: serviceAPI + "/api/v1/asana/users",
    projects: serviceAPI + "/api/v1/asana/projects",
  },
  assessment: {
    getPA: serviceAPINew + "/v2/pa/assessments/{0}/all",
  },

  healthPlan: {
    // getPatientHealthPlan: serviceAPI + "/api/v2/health-plan/{0}",
    // getPatientHealthPlan: serviceAPI + "/api/v1/hp/{0}",
    getPatientHealthPlan:
      serviceAPI + "/api/v1/hp/list/{0}?pageNo=1&pageSize=2",

    getPatientHealthPlanList:
      serviceAPI + "/v4/hp/list/{0}?pageNo=1&pageSize=2",
    getNewPatientHealthPlan: serviceAPI + "/v4/hp/{0}",
    //get latest hea;thplan
    fetchLatestHP: serviceAPI + "/api/v3/hp/{0}/latest",
    fetchNewLatestHP: serviceAPI + "/v4/hp/{0}/latest", //new

    fetchHpById: serviceAPI + "/api/v3/hp/{0}/{1}",
    fetchHpByIdNew: serviceAPI + "/v4/hp/{0}/{1}",
    fetchNewHpById: serviceAPI + "/v4/hp/{0}/{1}", //new
    //hea;thplan in list list
    updatePatientHealthPlan: serviceAPI + "/api/v2/health-plan/{0}",
    getPatientGoals: serviceAPI + "/api/v2/goals/{0}/short-term?page={1}",
    createPatientGoal: serviceAPI + "/api/v2/goals",
    updatePatientGoal: serviceAPI + "/api/v2/goals/{0}",
    getItems: serviceAPI + "/api/v2/health-plan/master?page={0}",
    getOptions: serviceAPI + "/api/v2/health-plan/master/options?page={0}",
    getGoals: serviceAPI + "/api/v2/goals/master?page={0}",
    /////

    healthTemplate: serviceAPI + "/api/v2/health-plan/template",
    healthGoalTemplate: serviceAPI + "/api/v2/goals/template",
    UpdatehealthTemplate: serviceAPI + "/api/v2/health-plan/template/{0}",
    UpdatehealthGoalTemplate: serviceAPI + "/api/v2/goals/template/{0}",
    levelUp: serviceAPI + "/api/v2/health-plan/{0}/level",
    ///template----emplate
    updateTemplate: serviceAPI + "/api/v1/hp/template/{0}?nameUpdated={1}",
    template: serviceAPI + "/api/v1/hp/template",
    templatebyId: serviceAPI + "/api/v1/hp/template/{0}",
    searchTemplate: serviceAPI + "/api/v1/hp/template/search/{0}?query={1}",

    ///////////////////////categoey
    getCategory: serviceAPI + "/api/v1/category",
    createCategory: serviceAPI + "/api/v1/category",
    //items
    getItemsByCategory: serviceAPI + "/api/v1/item/category/{0}",
    createItem: serviceAPI + "/api/v1/item",
    updateItem: serviceAPI + "/api/v1/item/{0}",
    getIitemById: serviceAPI + "/api/v1/item/{0}",
    searchItem: serviceAPI + "/api/v1/item/search?query={0}",
    //option serach
    getOptionsByName: serviceAPI + "/api/v1/option/search/{0}?query={1}",

    ///options
    createOption: serviceAPI + "/api/v1/option",
    // fetchOptions:serviceAPI+"/api/v1/option/{0}",
    fetchOptions: serviceAPI + "/api/v1/option?pageNo={0}&pageSize=30",
    updateOption: serviceAPI + "/api/v1/option/{0}",

    //halthlan craeete
    createHp: serviceAPI + "/api/v1/hp/{0}",
    updateHp: serviceAPI + "/api/v1/hp/{0}/{1}",

    createNewHp: serviceAPI + "/v4/hp/{0}",
    updateNewHp: serviceAPI + "/v4/hp/{0}/{1}",
    // food Preferences
    getFoodPreferences: serviceAPI + "/api/v1/fp/{0}",
    getRoutine: serviceAPI + "/api/v1/routine/{0}",

    foodList: serviceAPI + "/v1/foodlist/{0}/latest?filter={1}", //new

    foodListFilter: serviceAPI + "/api/v1/foodlist/fliter",
    searchFood: serviceAPI + "/foodlist/search?food={0}?&filter={1}?",

    ingredientUnit: serviceAPI + "/v1/ingredients/units",

    // pdf status
    hpPDFStatus: serviceAPI + "/v1/hp-pdf/{0}/{1}",
    generateHPPdf: serviceAPI + "/v1/hp-pdf",
  },
  imageUpload: {
    //post
    image: serviceAPI + "/api/v1/option/file/upload",
  },

  triviaMessage: {
    get: serviceAPI + "/statemachine/scheduler/dynamic/messages/list",
    new: serviceAPI + "/statemachine/scheduler/dynamic/message/create",
    update: serviceAPI + "/statemachine/scheduler/dynamic/message/{0}/update",
  },

  tasksReport: {
    get: serviceAPI + '/api/v2/tasks/report?where={"patientId":{0}}',
  },

  healthGoalsDashboard: {
    get: serviceAPI + "/api/v2/goals/master?page={0}",
    new: serviceAPI + "/api/v2/goals/master",
    update: serviceAPI + "/api/v2/goals/master/{0}",
  },

  healthTasksDashboard: {
    get: serviceAPI + "/api/v2/tasks/master?page={0}",
    new: serviceAPI + "/api/v2/tasks/master",
    update: serviceAPI + "/api/v2/tasks/master/{0}",
  },

  medicineOrders: {
    get: serviceAPI + "/api/v1/medicine/orders?page={0}",
    update: serviceAPI + "/api/v1/medicine/orders/{0}",
  },

  escalation: {
    getAll:
      serviceAPI + '/api/v1/vitals/escalations?page={0}&sort=[("_id",-1)]',
    update: serviceAPI + "/api/v1/vitals/escalations/{0}",
    updateStatus: serviceAPI + "/api/v1/vitals/escalations/status/update",
  },

  newAppFeatureDashboard: {
    get: serviceAPI + "/api/v1/release/version/features",
    new: serviceAPI + "/api/v1/release/version/features",
    update: serviceAPI + "/api/v1/release/version/features/{0}",
  },

  fileService: {
    new: serviceAPI + "/api/v1/file",
    get: serviceAPI + "/api/v1/file/{0}",
  },

  healthPlanDashboard: {
    get: serviceAPI + "/api/v2/health-plan/master?page={0}",
    getOptions: serviceAPI + "/api/v2/health-plan/master/options?page={0}",
    newOption: serviceAPI + "/api/v2/health-plan/master/options",
    updateOption: serviceAPI + "/api/v2/health-plan/master/options/{0}",
    new: serviceAPI + "/api/v2/health-plan/master",
    update: serviceAPI + "/api/v2/health-plan/master/{0}",
    csvUpload: serviceAPI + "/api/v2/health-plan/master/upload",
    //options
    fetchNewOptions: serviceAPI + "/v4/option?pageNo={0}&pageSize=50",
    getOptionsByName: serviceAPI + "/v4/option/search?query={0}",
    createNewOption: serviceAPI + "/v4/option",
    newOptionsById: serviceAPI + "/v4/option/{0}",
    newOptionsByIdDetails: serviceAPI + "/v4/option/detail/{0}", ///new
    newOptionsByItemId:
      serviceAPI + "/v4/option/item/{0}?pageNo={1}&pageSize=50",
    updateOptions: serviceAPI + "/v4/option/{0}",
    getOptionsByItem: serviceAPI + "/v4/option/search/opt/{0}?query={1}",
    // "/v4/option/item/{0}?query={1}",
    //items
    fetchNewItems: serviceAPI + "/v4/item",
    fetchNewItemsByCategory: serviceAPI + "/v4/item/category/{0}",
    createNewItem: serviceAPI + "/v4/item",
    updateNewItem: serviceAPI + "/v4/item/{0}",
    searchNewItem: serviceAPI + "/v4/item/search?query={0}",
    //category
    getNewCategory: serviceAPI + "/v4/category",
    //
    generateHp: serviceAPI + "/v4/hp/nutrition/{0}/{1}",
    //guidelines
    guidelines: serviceAPI + "/v1/guidelines/cat/{0}",
  },
  bridgeEmployee: {
    getAll: baseAPI + "/employees/all/profile",
    getById: baseAPI + "/employees/{0}",
    activate: baseAPI + "/employees/{0}/activate",
    deactivate: baseAPI + "/employees/{0}/deactivate",
    add: baseAPI + "/employees/new",
  },
  prescription: {
    fetch:
      serviceAPINew +
      "/api/v1/prescription/Tracking/{0}?userId={1}&userName={2}",
    fetchReports: serviceAPINew + "/api/v1/prescription/tracking/li/{0}",
    fetchMedicine: serviceAPINew + "/api/v1/prescription/tracking/md/{0}",
    fetchChiefComplaints:
      serviceAPINew + "/api/v1/prescription/tracking/cc/{0}",
    fetchDiagnosisHistory:
      serviceAPINew + "/api/v1/prescription/tracking/dh/{0}",
    fetchReportsAndPersonalDetails:
      serviceAPINew +
      "/api/v1/prescription/tracking/rpd/{0}?userId={1}&userName={2}",
    fetchDoctorDetails: serviceAPINew + "/api/v1/prescription/tracking/dd/{0}",
    fetchLabTestDetails:
      serviceAPINew +
      "/api/v1/prescription/tracking/lt/{0}?userId={1}&userName={2}",
    draft: serviceAPINew + "/api/v1/prescription/draft/{0}",
    update: serviceAPINew + "/api/v1/prescription/update",
    add: serviceAPINew + "/api/v1/prescription/create",
    discard: serviceAPINew + "/api/v1/prescription/deletedraft/{0}",
    template: {
      instruction: {
        getAll: serviceAPINew + "/api/v1/prescription/instructions",
        getById: serviceAPINew + "/api/v1/prescription/instructions/{0}",
      },
      investigations: {
        getAll: serviceAPINew + "/api/v1/prescription/investigations",
        getById: serviceAPINew + "/api/v1/prescription/investigations/{0}",
      },
    },
    searchByAbbrLabtest:
      serviceAPI + "/api/v1/dt/labtest/search?queryString={0}",
    searchByAbbrSubLabtest:
      serviceAPI + "/api/v1/dt/labtest/search/subtest?queryString={0}",

    getLabtestInfo: serviceAPI + "/api/v1/dt/labtest/getby/{0}",
    latestPrescription: serviceAPI + "/api/v1/prescription/{0}",
  },
  vital: {
    latestVital: serviceAPI + "/api/v2/vitals/{0}/latest",
  },
  events: {
    log: serviceAPINew + "/v1/events",
  },
  planCreation: {
    options: serviceAPI + "/v1/plans/options",
    create: serviceAPI + "/v1/plans",
    // getAll: serviceAPI + "/api/v1/plan",
    getAll: serviceAPI + "/v1/plans?valid=true",
    getAllPlans: serviceAPI + "/v1/plans",
    getById: serviceAPI + "/api/v1/plan/{0}",
    // updatePlan: serviceAPI + "/api/v1/plan/Update/{0}",
    disable: serviceAPI + "/v1/plans/{0}/validity",
    update: serviceAPI + "/v1/plans/{0}",
  },
  planAssignment: {
    options: serviceAPI + "/v1/plans/options",
    assign: serviceAPI + "/v1/plans/assignments",
    unAssign: serviceAPI + "/v1/plans/assignments/unassign",
    updatePlan: serviceAPI + "/v1/plans/assignments/{0}",
    fetchAllPlan: serviceAPI + "/v1/plans/assignments/patients/{0}?active=all", //v2 plans
    // fetchPlan: serviceAPI + "/v1/plans/assignments/patients/{0}", //v1 plans
  },
  patientProgram: {
    patientSearch: serviceAPI + "/v1/plans/assignments/patients",
    downloadCsv:
      serviceAPI +
      "/v1/plans/assignments/download?size={0}&subscriptionMonth={1}&tab={2}&planId={3}",
    getAll:
      serviceAPI +
      "/v1/plans/assignments?size={0}&subscriptionMonth={1}&tab={2}&planId={3}",
    pages: serviceAPI + "{0}",
  },
  userNew: {
    get: baseAPI + "/users?maxId={0}&minId={1}&size={2}",
    getUserByID: baseAPI + "/users/{0}",
    getRolesById: baseAPI + "/userroles/{0}",
    roles: baseAPI + "/roles",
    updateRoles: baseAPI + "/userroles/{0}",
    search: baseAPI + "/users/search?phoneNo={0}&countryCode={1}&who={2}",
    search2: baseAPI + "/users/search?phoneNo={0}&countryCode={1}",
  },

  language: {
    get: baseAPI + "/language",
    getPatientLanguage: baseAPI + "/patients/{0}/ppl",
    add: baseAPI + "/patients/{0}/ppl",
  },
  alternatePhoneNo: {
    get: baseAPI + "/patients/{0}/pan",
    add: baseAPI + "/patients/{0}/pan",
    update: baseAPI + "/patients/{0}/pan/{1}",
  },
  followUp: {
    fetchLatest: baseAPI + "/followup/latest/{0}",
    add: baseAPI + "/followup",
    get: serviceAPI + "/api/v1/followup/{0}?pageNumber={1}&pageSize={2}",
    new: serviceAPI + "/api/v1/followup",
    update: serviceAPI + "/api/v1/followup/{0}",
    primaryDoctorById: serviceAPI + "/api/v1/followup/doctor/{0}",
    doctorNameById: baseAPI + "/docprofile/doctor/{0}",
    count: serviceAPI + "/api/v1/followup/count/{0}",
  },
  labtestBooking: {
    get: serviceAPI + "/v1/labtest-bookings?size={0}",
    put: serviceAPI + "/v1/labtest-bookings/{0}",
    downloadCsv: serviceAPI + "/v1/labtest-bookings/download",
  },
  vitalExtraction: {
    get:
      serviceAPI +
      "/api/v1/vitalextraction/{0}?startDate={1}&endDate={2}&userId={3}&userName={4}",
  },
  medicineDashboard: {
    create: localService + "/api/v1/dt/medicines/create",
    updateMedicine: localService + "/api/v1/dt/medicines/update/{0}/{1}",
    getMedicines:
      localService + "/api/v1/dt/medicines/searchMedicine?queryString={0}",
    getMedicinesById: localService + "/api/v1/dt/medicines/getById/{0}",
    fetchPipeline: localService + "/api/v1/dt/medicines/fetchPipeline",
    fetchVerified: localService + "/api/v1/dt/medicines/verify",
    patientCount:
      localService + "/api/v1/dt/medicines/patientCount?queryString={0}",
    saltByName: localService + "/api/v1/dt/medicines/salt/{0}/{1}",
    saltByNameCode:
      localService + "/api/v1/dt/medicines/salt/{0}/{1}?therapyArea={2}",
    saltUnit: localService + "/api/v1/dt/medicines/saltUom",
    addToPipeline: localService + "/api/v1/dt/medicines/addToPipeline",
    delete: localService + "/api/v1/dt/medicines/delete/{0}",
    manufacturer: localService + "/api/v1/dt/medicines/manufacturer",
    salt: localService + "/api/v1/dt/medicines/saltUom",
    getUpdatedMeidcines:
      localService +
      "/api/v1/dt/medicines/getUpdatedMeidcines?userId={0}&pageNo={1}&startDate={2}&endDate={3}",
    getCountonStatus:
      localService + "/api/v1/dt/medicines/getCountOnStatus?status={0}",
  },
  tentacle: {
    disposition: serviceAPI + "/api/v1/web-hook/health-assessment/tentacle",
    fetchDetails:
      serviceAPI + "/api/v1/web-hook/health-assessment/disposition?phone={0}",
  },
  whitelisting: {
    getAll: serviceAPI + "/v1/clientconfig",
    add: serviceAPI + "/v1/clientconfig", ///getAlll//post
    getById: serviceAPI + "/v1/clientconfig/{0}", //get/[ut]
    update: serviceAPI + "/v1/clientconfig/{0}",
    bulk: serviceAPI + "/v1/clientconfig/{0}/phone/bulk", //post
    phoneNo: serviceAPI + "/v1/clientconfig/{0}/phone”", //post
    getPhoneList: serviceAPI + "/v1/clientconfig/{0}/phone", //get list
    checkWhitelisted: serviceAPI + "/v1/clientconfig/details",
    uploadEmployeeData: serviceAPI + "/v1/clientconfig/{0}/wallet",
    getServiceByClientCode: serviceAPI + "/api/v1/navigo/service/client?cc={0}",
  },
  clientCode: {
    check: serviceAPI + "/v1/clientconfig/check/client?clientCode={0}",
  },
  getEmployees: {
    getAll: baseAPI + "/employees/all/profile",
  },
  // g.POST(“/details”, cc.GetClientDetails())
  //       g.POST(“/:id/phone/bulk”, oc.BulkUpload())
  //       g.POST(“/:id/phone”, oc.Save())
  clevertap: {
    eventCreate: serviceAPI + "/api/v1/clevertap/event",
  },
  device: {
    getAllDevices: serviceAPI + "/v1/med/device/{0}",
    fetchVitals:
      serviceAPI + "/api/v3/vitals/{0}/device?source={1}&forDate={2}",
    getSteps:
      serviceAPI + "/api/v1/sleep-step-tracker/steps/{0}?from={1}&to={2}",
    getSleep:
      serviceAPI + "/api/v1/sleep-step-tracker/sleep/{0}?from={1}&to={2}",
    getTrackerData:
      serviceAPI + "/api/v1/sleep-step-tracker/bridge-data/{0}?from={1}&to={2}",
  },
  documentMail: {
    audit: serviceAPI + "/api/v1/patient-communication/audit",
    sendEmail: serviceAPI + "/api/v1/patient-communication/sendEmail",
    uploadDocument: serviceAPI + "/api/v1/document/file/upload",
  },
  sms: {
    sendSms: serviceAPI + "/api/v1/patient-communication/sms",
  },
  // ingredient: {
  //   get: serviceAPI + "/v1/ingredients",
  // },
  recipeDashboard: {
    recipe: serviceAPI + "/v1/recipes?query={0}&generic={1}",
    recipeSearch: serviceAPI + "/v1/recipes?query={0}",
    getSingleRecipe: serviceAPI + "/v1/recipes/{0}",
    uploadRecImg: serviceAPI + "/api/v3/option/file/recipes/upload",
    createRecipe: serviceAPI + "/v1/recipes",
    updateRecipe: serviceAPI + "/v1/recipes/{0}",
    cuisines: serviceAPI + "/v1/cuisines",
    ingredient: serviceAPI + "/v1/ingredients/all",
    ingredientSearch: serviceAPI + "/v1/ingredients?query={0}",
    // "/v1/ingredients",
    instruction: serviceAPI + "/v1/instructions?filter={0}",
    createIngredient: serviceAPI + "/v1/ingredients",
    singleIngredient: serviceAPI + "/v1/ingredients/{0}",
    ingredientList: serviceAPI + "/v1/ingredients",
    ingredientCategory: serviceAPI + "/v1/ingredients/categories",
    preferenceList: serviceAPI + "/v1/ingredients/preferences",
    condition: serviceAPI + "/v1/ingredients/preferences?query={0}",
    conditionUpdate: serviceAPI + "/v1/ingredients/preferences",
    singleCondition: serviceAPI + "/v1/ingredients/preferences/{0}",

    // addCondition: serviceAPI + "/v1/ingredients/preferences",
  },
  guidelineDashboard: {
    preferences: serviceAPI + "/v1/preferences",
    specificGuideline: serviceAPI + "/v1/guidelines/{0}/dsg",
    guidelines: serviceAPI + "/v1/guidelines/cat/{0}",
    fetchCategories: serviceAPI + "/v4/category",
    imgUpload: serviceAPI + "/v1/guidelines/file/upload",
    fetchGuideline: serviceAPI + "/v1/guidelines/{0}",
    fetchgdm: serviceAPI + "/v1/guidelines/{0}/gdm",
    deleteSpecific: serviceAPI + "/v1/guidelines/{0}/dsg/{1}",
  },
  fetchDataTable: {
    get: serviceAPI + "/api/v1/dt/external",
  },
  commonAPIs: {
    getLoinc:
      serviceAPI + "/api/v1/dt/labtest/search/subtest/name?queryString={0}",
  },
  healthPlanMigration: {
    check: serviceAPI + "/v1/hpmgrn/check/{0}",
  },

  whatsapp: {
    event: serviceAPI + "/api/v1/zylawhatsapp/event",
    short: serviceAPI + "/api/v1/zylawhatsapp/short",
    user: serviceAPI + "/api/v1/zylawhatsapp/user",
  },
  navigoDashboard: {
    createAppointment: serviceAPI + "/api/v2/navigo/appointment",
    appointmentByPatient: serviceAPI + "/api/v1/navigo/appointment/patient/{0}",
    allAppointments:
      serviceAPI +
      "/api/v1/navigo/appointment?limit={0}&offset={1}&filter={2}&from={3}&to={4}&serviceType={5}&createdFrom={6}&createdTo={7}",
    getAllAppointmentByStatus:
      serviceAPI +
      "/api/v1/navigo/appointment?status={0}&limit={1}&offset={2}&filter={3}&from={4}&to={5}",
    getTimeSlots:
      serviceAPI +
      "/api/v1/navigo/appointment/available-slots?sid={0}&date={1}&email={2}",
    getAllServices: serviceAPI + "/api/v1/navigo/service",
    createService: serviceAPI + "/api/v1/navigo/service",
    getServiceByClientCode: serviceAPI + "/api/v1/navigo/service/client?cc={0}",
    getAllCalendars: serviceAPI + "/api/v1/calendar/emails",
    accessCalendars: serviceAPI + "/api/v1/calendar/login",
    updateAppointmentStatus: serviceAPI + "/api/v1/navigo/appointment/status",
    rescheduleAppointments:
      serviceAPI + "/api/v1/navigo/appointment/reschedule",
    uploadContent: serviceAPI + "/v1/contents/upload",
    getServicesByPatientId: serviceAPI + "/api/v1/navigo/service/patient/{0}",
    getTimeSlotForLabTest:
      serviceAPI + "/api/v2/navigo/appointment/available-slots",
    bookingForLabtest: serviceAPI + "/v1/rc/booking ",
    getCustomizedService:
      serviceAPI + "/api/v1/navigo/service/client/customize/{0}/{1}",
    createUpdateCustomizedService:
      serviceAPI + "/api/v1/navigo/service/client/customize",
    removeNavServiceFromClient:
      serviceAPI + "/api/v1/navigo/service/client/remove",
    uploadAppointCSV: serviceAPI + "/api/v1/navigo/upload",
    vendors: {
      all: serviceAPI + "/api/v1/navigo/vendor",
      packageVendor: serviceAPI + "/api/v1/navigo/package/mapping",
    },
    packages: {
      all: serviceAPI + "/api/v1/navigo/package",
    },
    updatePackage: serviceAPI + "/api/v1/navigo/appointment/vendor",
    getDocumentByAppointment:
      serviceAPI + "/api/v1/navigo/appointment/document/{0}",
    uploadDocumentDigital: serviceAPI + "/api/v1/navigo/appointment/document",
    uploadDocumentLabtestAutomation:
      serviceAPI + "/api/v1/labtest-automation-webhook",
    getallClaims: serviceAPI + "/v1/wallet/admin/reimbursement/all??size={0}&pageNum={1}&filter={2}&createdFrom={3}&createdTo={4}",
    updateClaim: serviceAPI + '/v1/wallet/admin/reimbursement/update/{0}',
    rejectReason: serviceAPI + "/v1/wallet/admin/reimbursement/reject/reasons",
    getTransaction: serviceAPI + "/v1/wallet/admin/{0}/transactions",
    getIcd: serviceAPI + "/api/v1/dt/icd/search?queryString={0}",
    getDocuments: serviceAPI + "/v1/documents/{0}"
  },
  familyPlan: {
    getAllRelation: serviceAPI + "/v1/family/relations",
    primaryAddress: serviceAPI + "/api/v1/address?patientId={0}",
    updatePrimaryAddress: serviceAPI + "/api/v1/address/{0}?patientId={1}",
    getFamilyMembers: serviceAPI + "/v1/family/{0}?includeExtended=true",
    familyAddress: serviceAPI + "/api/v1/address",
    getMinorMembers: serviceAPI + "/v1/family/getminormembers",
    addMinorMember: serviceAPI + "/v1/family/minor",
  },
  documentApis: {
    getDoc: serviceAPI + "/v1/documents/{0}",
    getByPatientDoc: serviceAPI + "/v1/documents/patients/{0}?groupBy=type",
    uploadDoc: serviceAPI + "/v1/documents",
    getAllFolders: serviceAPI + "/v1/documents/folders",
    updateType: serviceAPI + "/v1/documents/{0}/type",
    updateNameAndDescription: serviceAPI + "/v1/documents/{0}/name",
    tokenGenerate: baseAPI + "/documents/token/generate",
  },
  callServetel: {
    getAgent:
      serviceAPI +
      "/api/v1/patient-communication/servetel/getAgent?phoneNo={0}",
    call: serviceAPI + "/api/v1/patient-communication/servetel/call",
  },
  doseScheduleApis: {
    getDrugs: serviceAPI + "/api/v1/nv/drug",
    // getDoseSchedule: serviceAPI + "/api/v1/nv/dose/get",
    getDoseSchedule: serviceAPI + "/api/v1/nv/dose/upcomingdosedate",
    createDoseSchedule: serviceAPI + "/api/v1/nv/dose/create",
    updateDoseSchedule: serviceAPI + "/api/v1/nv/dose/update",
  },
};

export default APIConfig;
