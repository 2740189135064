import AuthenticationService from "service/auth/AuthenticationService";
import Helper from "util/Helper";

class ApiService {
  constructor() {
    this.KEY_CLIENT = "client";
    this.KEY_PHONENO = "phone_no";
    this.KEY_TOKEN = "auth_token";

    this.CLIENT = "bridge_web";
    this.DOCTOR_CLIENT = "doctor_web";

    this.authenticationService = new AuthenticationService();
    this.helper = new Helper();

    this.initializeHeaders();
  }

  initializeHeaders() {
    let user = this.authenticationService.getUserProfile();
    let phoneNo = this.authenticationService.isLoggedIn() ? user.phoneno : "";
    let token = this.authenticationService.isLoggedIn()
      ? this.authenticationService.getSessionToken()
      : "";
    let isDoctor = false;
    if (user && user.employeeProfile && user.employeeProfile.id > 0) {
      isDoctor = false;
    } else if (
      user &&
      user.employeeProfile &&
      user.employeeProfile.id > 0 &&
      user.doctorProfile &&
      user.doctorProfile.id > 0
    ) {
      isDoctor = false;
    } else if (user && user.doctorProfile && user.doctorProfile.id > 0) {
      isDoctor = true;
    } else {
      isDoctor = false;
    }

    this.headers = {
      client: isDoctor ? this.DOCTOR_CLIENT : this.CLIENT,
      "Content-Type": "application/json",
    };

    if (phoneNo !== "") {
      this.headers.phone_no = phoneNo;
    }

    if (token !== "") {
      this.headers.auth_token = token;
    }
  }

  get(url, onResponse, onError1) {
    var onError = (err) => {
      var onResponse1 = (res) => {
        onResponse(res);
      };
      var onError = (err) => {
        onError1(err);
      };
      this.getAgain(url, onResponse1, onError);
    };
    let that = this;
    return fetch(url, {
      method: "GET",
      headers: this.headers,
    })
      .then(function (response) {
        if (response.status === 204) {
          onResponse(undefined);
          return;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response

        response
          .json()
          .then(function (data) {
            onResponse(data);
            return;
          })
          .catch((err) => {});
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  getAgain(url, onResponse, onError) {
    let that = this;
    return fetch(url, {
      method: "GET",
      headers: this.headers,
    })
      .then(function (response) {
        if (response.status === 204) {
          onResponse(undefined);
          return;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  getImage(url, onResponse, onError) {
    let that = this;

    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };

    return fetch(url, {
      method: "GET",
      headers: headers,
    })
      .then(function (response) {
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // return the buffer
        response.arrayBuffer().then((buffer) => {
          onResponse(buffer);
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  post(url, params, onResponse, onError1) {
    var onError = (err) => {
      var onResponse1 = (res) => {
        onResponse(res);
      };
      var onError = (err) => {
        onError1(err);
      };
      this.postAgain(url, params, onResponse1, onError);
    };

    let that = this;

    return fetch(url, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status === 201) {
          response.json().then(function (data) {
            onResponse(data);
            return;
          });
          return;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  postAgain(url, params, onResponse, onError) {
    let that = this;

    return fetch(url, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status === 201) {
          response.json().then(function (data) {
            onResponse(data);
            return;
          });
          return;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  postv2(url, params, onResponse, onError) {
    let that = this;

    return fetch(url, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status === 201) {
          response.json().then(function (data) {
            onResponse(data);
            return;
          });
          return;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  postWithFormData(url, params, onResponse, onError) {
    let that = this;
    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };
    return fetch(url, {
      method: "POST",
      headers: headers,
      body: params,
    })
      .then(function (response) {
        if (response.status !== 201 && response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  patchtWithFormData(url, params, onResponse, onError) {
    let that = this;
    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };
    return fetch(url, {
      method: "PATCH",
      headers: headers,
      body: params,
    })
      .then(function (response) {
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  putWithFormData(url, params, onResponse, onError) {
    let that = this;
    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };
    return fetch(url, {
      method: "PUT",
      headers: headers,
      body: params,
    })
      .then(function (response) {
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message:
              error && error.message !== undefined && error.message
                ? error.message
                : "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  put(url, params, onResponse, onError) {
    let that = this;

    fetch(url, {
      method: "PUT",
      headers: this.headers,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message:
                  error && error.message !== undefined && error.message
                    ? error.message
                    : "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message:
              error && error.message !== undefined && error.message
                ? error.message
                : "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  patch(url, params, onResponse, onError) {
    let that = this;

    fetch(url, {
      method: "PATCH",
      headers: this.headers,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  delete(url, onResponse, onError) {
    let that = this;
    return fetch(url, {
      method: "DELETE",
      headers: this.headers,
    })
      .then((response) => {
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Goal deleted.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch((error) => {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  downloadCSV(url, fileName, onResponse, onError) {
    return fetch(url, {
      method: "GET",
      headers: this.headers,
    })
      .then((response) => response.text())
      .then((response) => {
        var csv = response;
        var url = "data:text/plain;charset=utf-8," + encodeURIComponent(csv);
        //  window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = fileName + ".csv";
        document.body.appendChild(a);
        a.click();
        a.remove();
        onResponse({ success: "File Downloaded" });
      })
      .catch((error) => {
        if (this.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  getWithOptionalHeaders(url, header, onResponse, onError) {
    let that = this;
    return fetch(url, {
      method: "GET",
      headers: header,
    })
      .then(function (response) {
        if (response.status === 204) {
          onResponse(undefined);
          return;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  putWithOptionalHeaders(url, params, header, onResponse, onError) {
    let that = this;
    this.headers["Authorization"] = header.Authorization;
    fetch(url, {
      method: "PUT",
      headers: this.headers,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  downloadCSVwithOptionalHeader(url, fileName, header, onResponse, onError) {
    let that = this;
    this.headers["Authorization"] = header.Authorization;
    return fetch(url, {
      method: "GET",
      headers: this.headers,
    })
      .then((response) => response.text())
      .then((response) => {
        var csv = response;
        var url = "data:text/plain;charset=utf-8," + encodeURIComponent(csv);
        //  window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = fileName + ".csv";
        document.body.appendChild(a);
        a.click();
        a.remove();
        onResponse({ success: "File Downloaded" });
      })
      .catch((error) => {
        if (this.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  postWithOptionalHeaders(url, params, headers, onResponse, onError1) {
    var onError = (err) => {
      var onResponse = (res) => {
        onResponse(res);
      };
      var onError = (err) => {
        onError1(err);
      };
      this.postAgain(url, params, onResponse, onError);
    };

    let that = this;

    return fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(params),
    })
      .then(function (response) {
        if (response.status === 201) {
          response.json().then(function (data) {
            onResponse(data);
            return;
          });
          return;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
  postWithFormDataDownload(url, params, onResponse, onError) {
    let that = this;
    let headers = {
      client: this.headers.client,
      phone_no: this.headers.phone_no,
      auth_token: this.headers.auth_token,
    };
    return fetch(url, {
      method: "POST",
      headers: headers,
      body: params,
    })
      .then(function (response) {
        if (response.status !== 201 && response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }

        // Examine the text in the response
        response.blob().then((data) => {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (that.helper.isJSON(error)) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }
}

export default ApiService;
